<template>
  <!-- 应用管理报表管理 -->
  <div>
    <!-- 文字 -->
    <div class="text">
      <h4>应用管理报表</h4>
    </div>
    <!-- 新增按钮 -->
    <div class="onBtn">
      <el-button type="danger" style="margin-left: auto">新增</el-button>
    </div>
    <!-- 内容区域 -->
    <div>
      <el-table :data="data" border style="width: 100%">
        <el-table-column prop="tokenName" label="序号" width="" align="center"> </el-table-column>
        <el-table-column prop="tokenEnglish" label="应用名称" width="" align="center"> </el-table-column>
        <el-table-column prop="tokenNum" label="应用金额" width="" align="center"> </el-table-column>
        <el-table-column prop="gross" label="应用对应数字通证" width="" align="center"> </el-table-column>
        <el-table-column prop="gross" label="前端显示" width="" align="center"> </el-table-column>
        <el-table-column prop="gross" label="商品形式" width="" align="center"> </el-table-column>
        <el-table-column prop="gross" label="状态" width="" align="center"> </el-table-column>
        <el-table-column prop="gross" label="操作人" width="" align="center"> </el-table-column>
        <el-table-column label="操作" align="center">
          <template>
            <el-button size="mini">查看</el-button>
            <el-button size="mini" type="danger">下线</el-button>
            <el-button size="mini" type="primary">重新审核</el-button>
          </template>
        </el-table-column>
      </el-table>
    </div>

    <!-- 分页 -->
    <div class="pagination">
      <el-pagination
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
        :current-page="currentPage4"
        :page-sizes="[10, 15, 20, 30]"
        :page-size="10"
        layout="total, sizes, prev, pager, next, jumper"
        :total="total"
      >
      </el-pagination>
    </div>
  </div>
</template>


<script>
export default {
  data() {
    return {
      userInfo: [],
      data: [],
      currentPage4: 4,
      total: 1
    }
  },
  methods: {
    // 分页
    handleSizeChange(val) {
      console.log(`每页 ${val} 条`)
    },
    handleCurrentChange(val) {
      console.log(`当前页: ${val}`)
    }
  }
}
</script>

<style lang="scss" scoped>
.text {
  color: #409eff;
}
.onBtn {
  display: flex;
  align-items: center;
  padding: 20px;
}
.btn {
  display: flex;
  justify-content: center;
  div {
    margin: 0 3px;
    font-size: 12px;
    color: #409eff;
  }
  div:hover {
    cursor: pointer;
  }
}
.pagination {
  display: flex;
  justify-content: center;
  margin-top: 50px;
}
</style>